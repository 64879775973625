<template>
    <Error404Component    />
  </template>
    
    <script>
import Error404Component from '@/components/Error404Component.vue';  
  export default {
    components: {
      Error404Component,
    }
    }
    
  </script>
    