<template>
    <div class="content">
        <h1>404 Error: Page Not Found</h1>
        <p>Sorry, there is nothing at this route.</p>
        <router-link to="/">Go to Homepage</router-link>
    </div>
</template>

<script>
export default {
    name: "Error404Component",
};
</script>
