<template>
  <footer class="app-footer">
    <p>&copy; CoinHub 2023 </p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
</style>
